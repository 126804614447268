<template>
  <div class="body">
    <div class="container">
      <span class="girl"></span>
      <div class="boys">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../utils/index.js";
import axios from "axios";
export default {
  data() {
    return {
      // userName:vueConfig.bearlA
    };
  },
  mounted() {
    let userName = this.$route.query.userName;
    setTimeout(() => {
      this.exemptLogin(userName);
    }, 3000);
  },
  methods: {
    // 登录
    async exemptLogin(val) {
      if (val == undefined) {
        sessionStorage.clear();
        window.location.href = vueConfig.jqUrl + "vue/web/login";
        return false;
      }
      let confirmData = sessionStorage.getItem("confirm");
      console.log(confirmData, "confirmData");
      var params = {
        user: val,
        code: "noAuth",
        client: "web",
        method: 3, //第二次重新获取
      };
      let url = vueConfig.thePathaddress + "/httpServe/rest/v6/get/user/auth";
      axios
        .post(url, params)
        .then((res) => {
          console.log(res.data.code, "登录信息");
          if (res.data.code == 3) {
            this.$message.error("用户不存在");
            setTimeout(()=>{
                sessionStorage.clear();
                window.location.href = vueConfig.jqUrl + "vue/web/login";
            },5000)
          }
          if (res.data.code == 14) {
            this.$message.error("用户已停用");
            setTimeout(()=>{
                sessionStorage.clear();
                window.location.href = vueConfig.jqUrl + "vue/web/login";
            },5000)
          }
          if (res.data.code == 40) {
            this.$message.error("免认证用户不存在");
            setTimeout(()=>{
                sessionStorage.clear();
                window.location.href = vueConfig.jqUrl + "vue/web/login";
            },5000)
          }
          if (res.data.code == 13) {
            this.$message.error("用户已锁定");
            setTimeout(()=>{
                sessionStorage.clear();
                window.location.href = vueConfig.jqUrl + "vue/web/login";
            },5000)
          }
          if (res.data.code == 200) {
            this.getUserInfo(val,res.data.data.uuid);
          }else if(res.data.code == 500) {
            setTimeout(()=>{
                sessionStorage.clear();
                window.location.href = vueConfig.jqUrl + "vue/web/login";
            },5000)
          }
        })
        .catch(() => {
          console.log("error submit!!");
        });
    },
    async getUserInfo(val,uuid) {
      if (val == undefined) {
        sessionStorage.clear();
        window.location.href = vueConfig.jqUrl + "vue/web/login";
        return false;
      }
      let confirmData = sessionStorage.getItem("confirm");
      console.log(confirmData, "confirmData");
      var params = {
        userName: val,
        code: "noAuth",
        passWord:'',
        newPassword: "",
        method: 3,
        uuid:uuid,
      };
      let url = vueConfig.thePathaddress + "/httpServe/login";
      axios
        .post(url, params, {
          headers: {
            confirm: confirmData,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$store.commit("changeLogin", "100"); //登录后改变登录状态
            sessionStorage.setItem("groupId", res.data.data.groupId);
            sessionStorage.setItem(
              "groupFullNameShowByIndex",
              res.data.data.groupFullNameShowByIndex
            );
            sessionStorage.setItem("roleId", res.data.data.roleId);
            sessionStorage.setItem(
              "Authorization",
              res.data.data.authorization
            );
            sessionStorage.setItem(
              "manageGroupId",
              res.data.data.manageGroupId
            );
            sessionStorage.setItem(
              "manageGroupFullName",
              res.data.data.manageGroupFullName
            );
            sessionStorage.setItem("userId", res.data.data.userId);
            sessionStorage.setItem("userName", res.data.data.loginName);
            sessionStorage.setItem("oo", 1);
            if (res.data.data.roleId == 1) {
              this.$router.push("/SourceHome");
            } else if (res.data.data.roleId == 2) {
              this.$router.push("/ManageLog");
            } else if (res.data.data.roleId == 3) {
              this.$router.push("/Running");
            } else if (
              res.data.data.roleId != 1 ||
              res.data.data.roleId != 2 ||
              res.data.data.roleId != 3
            ) {
              this.$router.push("/SourceHome");
            }
          }
        })
        .catch(() => {
          console.log("error submit!!");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  margin: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 8em;
  height: 1em;
  font-size: 35px;
  display: flex;
  justify-content: space-between;
}

.container span {
  width: 1em;
  height: 1em;
  --duration: 1.5s;
}

.girl {
  animation: slide var(--duration) ease-in-out infinite alternate;
}

@keyframes slide {
  from {
    transform: translateX(0);
    filter: brightness(1);
  }

  to {
    transform: translatex(calc(8em - (1em * 1.25)));
    filter: brightness(1.45);
  }
}

.boys {
  width: 6em;
  display: flex;
  justify-content: space-between;
}

.boys span {
  animation: var(--duration) ease-in-out infinite alternate;
}

.boys span:nth-child(1) {
  animation-name: jump-off-1;
}

.boys span:nth-child(2) {
  animation-name: jump-off-2;
}

.boys span:nth-child(3) {
  animation-name: jump-off-3;
}

.boys span:nth-child(4) {
  animation-name: jump-off-4;
}

@keyframes jump-off-1 {
  0%,
  15% {
    transform: rotate(0deg);
  }

  35%,
  100% {
    transform-origin: -50% center;
    transform: rotate(-180deg);
  }
}

@keyframes jump-off-2 {
  0%,
  30% {
    transform: rotate(0deg);
  }

  50%,
  100% {
    transform-origin: -50% center;
    transform: rotate(-180deg);
  }
}

@keyframes jump-off-3 {
  0%,
  45% {
    transform: rotate(0deg);
  }

  65%,
  100% {
    transform-origin: -50% center;
    transform: rotate(-180deg);
  }
}

@keyframes jump-off-4 {
  0%,
  60% {
    transform: rotate(0deg);
  }

  80%,
  100% {
    transform-origin: -50% center;
    transform: rotate(-180deg);
  }
}

.container span::before {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 15%;
  box-shadow: 0 0 0.1em rgba(0, 0, 0, 0.3);
}

.girl::before {
  background-color: hotpink;
}

.boys span::before {
  background-color: dodgerblue;
  animation: var(--duration) ease-in-out infinite alternate;
}

.boys span:nth-child(1)::before {
  filter: brightness(1);
  animation-name: jump-down-1;
}

.boys span:nth-child(2)::before {
  filter: brightness(1.15);
  animation-name: jump-down-2;
}

.boys span:nth-child(3)::before {
  filter: brightness(1.3);
  animation-name: jump-down-3;
}

.boys span:nth-child(4)::before {
  filter: brightness(1.45);
  animation-name: jump-down-4;
}

@keyframes jump-down-1 {
  5% {
    transform: scale(1, 1);
  }

  15% {
    transform-origin: center bottom;
    transform: scale(1.3, 0.7);
  }

  20%,
  25% {
    transform-origin: center bottom;
    transform: scale(0.8, 1.4);
  }

  40% {
    transform-origin: center top;
    transform: scale(1.3, 0.7);
  }

  55%,
  100% {
    transform: scale(1, 1);
  }
}

@keyframes jump-down-2 {
  20% {
    transform: scale(1, 1);
  }

  30% {
    transform-origin: center bottom;
    transform: scale(1.3, 0.7);
  }

  35%,
  40% {
    transform-origin: center bottom;
    transform: scale(0.8, 1.4);
  }

  55% {
    transform-origin: center top;
    transform: scale(1.3, 0.7);
  }

  70%,
  100% {
    transform: scale(1, 1);
  }
}

@keyframes jump-down-3 {
  35% {
    transform: scale(1, 1);
  }

  45% {
    transform-origin: center bottom;
    transform: scale(1.3, 0.7);
  }

  50%,
  55% {
    transform-origin: center bottom;
    transform: scale(0.8, 1.4);
  }

  70% {
    transform-origin: center top;
    transform: scale(1.3, 0.7);
  }

  85%,
  100% {
    transform: scale(1, 1);
  }
}

@keyframes jump-down-4 {
  50% {
    transform: scale(1, 1);
  }

  60% {
    transform-origin: center bottom;
    transform: scale(1.3, 0.7);
  }

  65%,
  70% {
    transform-origin: center bottom;
    transform: scale(0.8, 1.4);
  }

  85% {
    transform-origin: center top;
    transform: scale(1.3, 0.7);
  }

  100%,
  100% {
    transform: scale(1, 1);
  }
}
</style>